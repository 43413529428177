import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CssBaseline, Typography, useMediaQuery } from '@mui/material';
import CampaignList from './components/LeftPanel/CampaignList';
import Overview from './components/CenterPanel/Overview';
import DispatchList from './components/RightPanel/DispatchList';
import CampaignListMobile from './components/Mobile/CampaignListMobile';
import OverviewMobile from './components/Mobile/OverviewMobile';
import { getCampaigns, getCampaignLogs, getCampaignDays, getCampaignStats } from './services/supabase';

const theme = createTheme({
  palette: {
    primary: {
      main: '#25D366',
    },
    secondary: {
      main: '#FF6B6B',
    },
  },
});

function App() {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedDay, setSelectedDay] = useState('all');
  const [logs, setLogs] = useState([]);
  const [days, setDays] = useState([]);
  const [stats, setStats] = useState({
    totalEnvios: 0,
    sucessos: 0,
    falhas: 0
  });
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showCampaignList, setShowCampaignList] = useState(true);

  useEffect(() => {
    loadCampaigns();
  }, []);

  useEffect(() => {
    if (selectedCampaign) {
      loadCampaignData();
    }
  }, [selectedCampaign, selectedDay, page]);

  useEffect(() => {
    if (selectedCampaign) {
      loadCampaignStats();
    }
  }, [selectedCampaign]);

  const loadCampaigns = async () => {
    try {
      const data = await getCampaigns();
      setCampaigns(data);
      if (data.length > 0) {
        setSelectedCampaign(data[0]);
      }
    } catch (error) {
      console.error('Erro ao carregar campanhas:', error);
    }
  };

  const loadCampaignData = async () => {
    try {
      const [logsData, daysData] = await Promise.all([
        getCampaignLogs(selectedCampaign.id, selectedDay, page, 100),
        getCampaignDays(selectedCampaign.id)
      ]);

      setLogs(logsData.logs);
      setTotal(logsData.total);
      setDays(daysData);
    } catch (error) {
      console.error('Erro ao carregar dados da campanha:', error);
    }
  };

  const loadCampaignStats = async () => {
    try {
      console.log('Carregando stats para campanha:', selectedCampaign?.id);
      const statsData = await getCampaignStats(selectedCampaign.id);
      console.log('Stats carregados:', statsData);
      setStats({
        totalEnvios: statsData.count,
        sucessos: statsData.sucessos,
        falhas: statsData.falhas
      });
    } catch (error) {
      console.error('Erro ao carregar estatísticas:', error);
    }
  };

  const handleDayChange = (day) => {
    setSelectedDay(day);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleBack = () => {
    setShowCampaignList(true);
  };

  const handleCampaignSelect = (campaign) => {
    setSelectedCampaign(campaign);
    if (isMobile) {
      setShowCampaignList(false);
    }
  };

  if (isMobile) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {showCampaignList ? (
          <CampaignListMobile
            campaigns={campaigns}
            selectedCampaign={selectedCampaign}
            onSelectCampaign={handleCampaignSelect}
          />
        ) : (
          <OverviewMobile
            campaign={selectedCampaign}
            stats={stats}
            days={days}
            selectedDay={selectedDay}
            onDayChange={handleDayChange}
            onBack={handleBack}
            total={total}
          />
        )}
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box 
        sx={{ 
          display: 'flex', 
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          bgcolor: '#f8f9fa'
        }}
      >
        <Box sx={{ width: 300, flexShrink: 0 }}>
          <CampaignList
            campaigns={campaigns}
            selectedCampaign={selectedCampaign}
            onSelectCampaign={setSelectedCampaign}
          />
        </Box>
        <Box sx={{ 
          flex: 1, 
          overflowY: 'auto', 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontWeight: 500,
              mb: 4,
              color: '#1a1a1a'
            }}
          >
            PAINEL DE VISUALIZAÇÃO DE CAMPANHAS
          </Typography>
          <Overview 
            campaign={selectedCampaign}
            stats={stats}
          />
        </Box>
        <Box sx={{ width: 400, flexShrink: 0 }}>
          <DispatchList
            days={days}
            selectedDay={selectedDay}
            onDayChange={handleDayChange}
            logs={logs}
            total={total}
            page={page}
            onPageChange={handlePageChange}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
