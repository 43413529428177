import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, 
  Typography, 
  Paper,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Chip
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Container = styled(Box)({
  minHeight: '100vh',
  backgroundColor: '#f8f9fa',
  padding: '16px'
});

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
  gap: '16px'
});

const Title = styled(Typography)({
  fontSize: '20px',
  fontWeight: 600,
  flex: 1,
  color: '#1a1a1a'
});

const MetricsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '24px'
});

const MetricCard = styled(Paper)({
  padding: '16px',
  borderRadius: '12px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  display: 'flex',
  alignItems: 'center',
  gap: '16px'
});

const IconWrapper = styled(Box)(({ color }) => ({
  width: '48px',
  height: '48px',
  borderRadius: '12px',
  background: `linear-gradient(135deg, ${color} 0%, ${color}dd 100%)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    color: '#fff',
    fontSize: '24px'
  }
}));

const MetricValue = styled(Typography)({
  fontSize: '24px',
  fontWeight: 600,
  color: '#1a1a1a',
  marginBottom: '4px'
});

const MetricLabel = styled(Typography)({
  fontSize: '14px',
  color: '#666'
});

const DaySelector = styled(Box)({
  marginTop: '32px'
});

const DayTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  color: '#1a1a1a',
  marginBottom: '16px'
});

const ChipsContainer = styled(Box)({
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
  marginBottom: '16px'
});

const DayChip = styled(Chip)(({ selected }) => ({
  backgroundColor: selected ? '#25D366' : '#fff',
  color: selected ? '#fff' : '#1a1a1a',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: selected ? '#25D366' : '#f5f5f5'
  }
}));

const TotalContainer = styled(Box)({
  marginTop: '16px',
  padding: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  textAlign: 'center'
});

const TotalText = styled(Typography)({
  fontSize: '16px',
  color: '#666',
  fontWeight: 500
});

const OverviewMobile = ({ campaign, stats, days, selectedDay, onDayChange, onBack, total }) => {
  const availableDays = days?.filter(day => day.status !== 'ignorado') || [];

  return (
    <Container>
      <Header>
        <IconButton onClick={onBack} edge="start">
          <ArrowBackIcon />
        </IconButton>
        <Title>
          {campaign?.nome}
        </Title>
      </Header>

      <MetricsContainer>
        <MetricCard>
          <IconWrapper color="#25D366">
            <WhatsAppIcon />
          </IconWrapper>
          <Box>
            <MetricValue>
              {stats?.totalEnvios || 0}
            </MetricValue>
            <MetricLabel>
              Total de Envios
            </MetricLabel>
          </Box>
        </MetricCard>

        <MetricCard>
          <IconWrapper color="#4CD964">
            <CheckCircleIcon />
          </IconWrapper>
          <Box>
            <MetricValue>
              {stats?.sucessos || 0}
            </MetricValue>
            <MetricLabel>
              Sucessos
            </MetricLabel>
          </Box>
        </MetricCard>

        <MetricCard>
          <IconWrapper color="#FF3B30">
            <ErrorIcon />
          </IconWrapper>
          <Box>
            <MetricValue>
              {stats?.falhas || 0}
            </MetricValue>
            <MetricLabel>
              Falhas
            </MetricLabel>
          </Box>
        </MetricCard>
      </MetricsContainer>

      <DaySelector>
        <DayTitle>
          Selecione um dia
        </DayTitle>
        <ChipsContainer>
          <DayChip
            label="Todos"
            onClick={() => onDayChange('all')}
            selected={selectedDay === 'all'}
          />
          {availableDays.map((day) => (
            <DayChip
              key={day.id}
              label={`Dia ${day.dia_numero}`}
              onClick={() => onDayChange(day.id)}
              selected={selectedDay === day.id}
            />
          ))}
        </ChipsContainer>

        <TotalContainer>
          <TotalText>
            Total de disparos: {total}
          </TotalText>
        </TotalContainer>
      </DaySelector>
    </Container>
  );
};

export default OverviewMobile;
