import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const getCampaigns = async () => {
  const { data, error } = await supabase
    .from('campanhas')
    .select(`
      *,
      dias (
        id,
        dia_numero,
        status
      )
    `)
    .order('created_at', { ascending: false });

  if (error) throw error;

  // Determina o status da campanha baseado nos dias
  return data.map(campaign => ({
    ...campaign,
    status: campaign.dias.some(day => day.status === 'em_execucao') ? 'running' :
            campaign.dias.some(day => day.status === 'pendente') ? 'pending' :
            'completed'
  }));
};

export const getCampaignStats = async (campaignId, dayId = null) => {
  console.log('Iniciando getCampaignStats:', { campaignId, dayId });
  
  // Query para total
  let queryTotal = supabase
    .from('logs')
    .select('*', { count: 'exact' });

  // Query para sucessos
  let querySucessos = supabase
    .from('logs')
    .select('*', { count: 'exact' })
    .eq('sucesso', true);

  // Query para falhas
  let queryFalhas = supabase
    .from('logs')
    .select('*', { count: 'exact' })
    .eq('sucesso', false);

  // Aplicar filtro de dias não ignorados em todas as queries
  const { data: dias } = await supabase
    .from('dias')
    .select('id')
    .eq('campanha_id', campaignId)
    .neq('status', 'ignorado');

  if (dias?.length > 0) {
    const diaIds = dias.map(d => d.id);
    queryTotal = queryTotal.in('dia_id', diaIds);
    querySucessos = querySucessos.in('dia_id', diaIds);
    queryFalhas = queryFalhas.in('dia_id', diaIds);
  }

  // Executar todas as queries em paralelo
  const [totalResult, sucessosResult, falhasResult] = await Promise.all([
    queryTotal,
    querySucessos,
    queryFalhas
  ]);

  if (totalResult.error) throw totalResult.error;
  if (sucessosResult.error) throw sucessosResult.error;
  if (falhasResult.error) throw falhasResult.error;

  const stats = {
    count: totalResult.count || 0,
    sucessos: sucessosResult.count || 0,
    falhas: falhasResult.count || 0
  };

  console.log('Stats calculados:', stats);
  return stats;
};

export const getCampaignLogs = async (campaignId, dayId = null, page = 1, pageSize = 100) => {
  let query = supabase
    .from('logs')
    .select('*', { count: 'exact' });

  // Se um dia específico foi selecionado
  if (dayId && dayId !== 'all') {
    query = query.eq('dia_id', dayId);
  } else {
    // Se selecionou "Todos", busca os logs dos dias não ignorados
    const { data: campanhaDias } = await supabase
      .from('campanha_dias')
      .select(`
        dia_id,
        dias!inner (
          status
        )
      `)
      .eq('campanha_id', campaignId)
      .neq('dias.status', 'ignorado');

    if (campanhaDias?.length > 0) {
      const diaIds = campanhaDias.map(cd => cd.dia_id);
      query = query.in('dia_id', diaIds);
    }
  }

  // Adiciona ordenação e paginação
  const from = (page - 1) * pageSize;
  const to = from + pageSize - 1;

  const { data: logs, error, count } = await query
    .order('enviado_em', { ascending: false })
    .range(from, to);

  if (error) throw error;
  return { logs: logs || [], total: count };
};

export const getCampaignDays = async (campaignId) => {
  const { data, error } = await supabase
    .from('campanha_dias')
    .select(`
      dias:dia_id (
        id,
        dia_numero,
        inicio,
        status,
        tipo,
        texto_ou_legenda,
        midia_url,
        destinatarios_string,
        intervalo_minimo,
        intervalo_maximo
      )
    `)
    .eq('campanha_id', campaignId)
    .order('dia_id', { ascending: true });

  if (error) throw error;
  
  // Transformar o resultado para ficar mais simples
  return data?.map(item => item.dias) || [];
};
