import React from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, 
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Container = styled(Box)({
  height: '100vh',
  backgroundColor: '#f8f9fa',
  padding: '16px'
});

const Title = styled(Typography)({
  fontSize: '24px',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '24px',
  color: '#1a1a1a'
});

const CampaignCard = styled(Box)(({ status, selected }) => ({
  background: 
    status === 'running' ? 'linear-gradient(90deg, #FF6B6B 0%, #FF8E8E 100%)' :
    status === 'pending' ? 'linear-gradient(90deg, #FFD93D 0%, #FFA63D 100%)' :
    'linear-gradient(90deg, #4CD964 0%, #2ECC71 100%)',
  borderRadius: '12px',
  padding: '16px',
  marginBottom: '12px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)'
  },
  ...(selected && {
    border: '2px solid #4caf50',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  })
}));

const CampaignInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
});

const CampaignName = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  color: '#fff'
});

const CampaignDate = styled(Typography)({
  fontSize: '14px',
  color: 'rgba(255, 255, 255, 0.9)'
});

const StatusIcon = styled(Box)({
  '& .MuiSvgIcon-root': {
    fontSize: 24,
    color: '#fff'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});

const CampaignListMobile = ({ campaigns, selectedCampaign, onSelectCampaign }) => {
  const formatDate = (date) => {
    return format(new Date(date), "d 'de' MMMM", { locale: ptBR });
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'running':
        return <AutorenewIcon sx={{ animation: 'spin 2s linear infinite' }} />;
      case 'pending':
        return <AccessTimeIcon />;
      default:
        return <CheckCircleIcon />;
    }
  };

  return (
    <Container>
      <Title>
        CAMPANHAS
      </Title>
      <Box>
        {campaigns.map((campaign) => (
          <CampaignCard 
            key={campaign.id}
            onClick={() => onSelectCampaign(campaign)}
            status={campaign.status}
            selected={selectedCampaign?.id === campaign.id}
          >
            <CampaignInfo>
              <CampaignName>
                {campaign.nome}
              </CampaignName>
              <CampaignDate>
                Criada em {formatDate(campaign.created_at)}
              </CampaignDate>
            </CampaignInfo>
            <StatusIcon>
              {getStatusIcon(campaign.status)}
            </StatusIcon>
          </CampaignCard>
        ))}
      </Box>
    </Container>
  );
};

export default CampaignListMobile;
